<template>
  <div class="mine-job-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="客服工单" name="third">
        <div class="mine-list-tools">
          <el-form class="battery-form" :inline="true" :model="formData">
            <el-form-item label="设备编号">
              <el-input
                v-model="formData.sn"
                placeholder="设备编号"
                size="small"
                class="area-form-item"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="工单状态">
              <el-select
                v-model="formData.status"
                placeholder="信息状态"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" :value="null"></el-option>
                <el-option label="已处理" :value="1"></el-option>
                <el-option label="未处理" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="formData.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item style="float: right; marginright: 0px">
              <el-button type="primary" @click="getData2()">搜索</el-button>
              <el-button @click="resetFrom()">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="mine-table-container">
          <div class="mine-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="设备编号" prop="sn" width="200">
                <template slot-scope="scope">
                  <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn || '-'}}</a>
                </template>
              </el-table-column>
              <el-table-column label="派发人" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.senderText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="派发时间" prop="sendTime" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sendTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="工单状态" prop="status" width="150">
                <template slot-scope="scope">
                  {{ scope.row.status | statusStr }}
                </template>
              </el-table-column>
              <el-table-column
                label="当前设备位置"
                prop="ompStatus"
                width="220"
              >
                <template slot-scope="scope">
                  {{ scope.row.currentStatus || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="处理时间" prop="ompStatus" width="200">
                <template slot-scope="scope">
                  {{
                    scope.row.finishTime || "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="处理人" prop="ompStatus">
                <template slot-scope="scope">
                  {{ scope.row.receiverText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="持续时间" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.interval || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.status === 0"
                    @click="gotoCunstomer(scope.row)"
                    type="text"
                    >处理</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mine-table-page">
            <div class="mine-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="运维工单" name="fourth">
        <div class="mine-list-tools">
          <el-form class="battery-form" :inline="true" :model="formData">
            <el-form-item label="设备编号">
              <el-input
                v-model="formData.sn"
                placeholder="设备编号"
                size="small"
                class="area-form-item"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="工单状态">
              <el-select
                v-model="formData.faultStatus"
                placeholder="信息状态"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" :value="null"></el-option>
                <el-option label="已处理" :value="1"></el-option>
                <el-option label="未处理" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="紧急情况">
              <el-select
                v-model="formData.emergency"
                placeholder="紧急情况"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" :value="null"></el-option>
                <el-option label="紧急" :value="1"></el-option>
                <el-option label="一般" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="formData.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="云控类型">
              <el-select
                v-model="formData.type"
                placeholder="云控类型"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" :value="null"></el-option>
                <el-option label="故障" :value="1"></el-option>
                <el-option label="低电量" :value="2"></el-option>
                <el-option label="离线" :value="3"></el-option>
                <el-option label="客服" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="float: right; marginright: 0px">
              <el-button type="primary" @click="getOmpData()">搜索</el-button>
              <el-button @click="resetFrom()">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="mine-table-container">
          <div class="mine-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="设备编号" prop="sn" width="200">
                <template slot-scope="scope">
                  <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn || '-'}}</a>
                </template>
              </el-table-column>
              <el-table-column label="派发人" prop="sender" width="150">
                <template slot-scope="scope">
                  {{ scope.row.senderText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="派发时间" prop="sendTime" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sendTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="云控类型" prop="sendTime" width="100">
                <template slot-scope="scope">
                  {{ scope.row.type | typeStr }}
                </template>
              </el-table-column>
              <el-table-column label="紧急情况" prop="sendTime" width="100">
                <template slot-scope="scope">
                  {{ scope.row.emergency===0?'一般':'紧急' }}
                </template>
              </el-table-column>
              <el-table-column label="工单状态" prop="status" width="150">
                <template slot-scope="scope">
                  {{ scope.row.faultStatus | statusStr }}
                </template>
              </el-table-column>
              <el-table-column label="处理时间" prop="ompStatus" width="200">
                <template slot-scope="scope">
                  {{
                    scope.row.faultStatus === 1 ? scope.row.finishTime || "-" : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="处理人" prop="ompStatus">
                <template slot-scope="scope">
                  {{ scope.row.receiverText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="持续时间" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.interval || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.faultStatus === 0"
                    @click="gotoOmp(scope.row)"
                    type="text"
                    >处理</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mine-table-page">
            <div class="mine-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="回调工单" name="first">
        <div class="mine-list-tools">
          <el-form class="battery-form" :inline="true" :model="formData">
            <el-form-item label="设备编号">
              <el-input
                v-model="formData.sn"
                placeholder="设备编号"
                size="small"
                class="area-form-item"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="工单状态">
              <el-select
                v-model="formData.status"
                placeholder="信息状态"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" :value="null"></el-option>
                <el-option label="已处理" :value="1"></el-option>
                <el-option label="未处理" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="formData.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item style="float: right; marginright: 0px">
              <el-button type="primary" @click="getData()">搜索</el-button>
              <el-button @click="resetFrom()">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="mine-table-container">
          <div class="mine-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="设备编号" prop="sn" width="200">
                <template slot-scope="scope">
                  <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn || '-'}}</a>
                </template>
              </el-table-column>
              <el-table-column label="派发人" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.senderText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="派发时间" prop="sendTime" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sendTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="工单状态" prop="status" width="150">
                <template slot-scope="scope">
                  {{ scope.row.status | statusStr }}
                </template>
              </el-table-column>
              <el-table-column
                label="当前设备位置"
                prop="ompStatus"
                width="220"
              >
                <template slot-scope="scope">
                  {{ scope.row.currentStatus || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="处理时间" prop="ompStatus" width="200">
                <template slot-scope="scope">
                  {{
                    scope.row.status === 1 ? scope.row.updateTime || "-" : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="处理人" prop="ompStatus">
                <template slot-scope="scope">
                  {{ scope.row.receiverText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="持续时间" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.interval || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.status === 0"
                    @click="setHandle(scope.row)"
                    type="text"
                    >处理</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mine-table-page">
            <div class="mine-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="售后工单" name="second">
        <div class="mine-list-tools">
          <el-form class="battery-form" :inline="true" :model="formData">
            <el-form-item label="设备编号">
              <el-input
                v-model="formData.sn"
                placeholder="设备编号"
                size="small"
                class="area-form-item"
                style="width: 180px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="工单状态">
              <el-select
                v-model="formData.status"
                placeholder="信息状态"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" :value="null"></el-option>
                <el-option label="已处理" :value="1"></el-option>
                <el-option label="未处理" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="formData.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item style="float: right; marginright: 0px">
              <el-button type="primary" @click="getData1()">搜索</el-button>
              <el-button @click="resetFrom()">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="mine-table-container">
          <div class="mine-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="设备编号" prop="sn" width="200">
                <template slot-scope="scope">
                  <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn || '-'}}</a>
                </template>
              </el-table-column>
              <el-table-column label="派发人" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.senderText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="派发时间" prop="sendTime" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sendTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="工单状态" prop="status" width="150">
                <template slot-scope="scope">
                  {{ scope.row.status | statusStr }}
                </template>
              </el-table-column>
              <el-table-column
                label="当前设备位置"
                prop="ompStatus"
                width="220"
              >
                <template slot-scope="scope">
                  {{ scope.row.currentStatus || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="处理时间" prop="ompStatus" width="200">
                <template slot-scope="scope">
                  {{
                    scope.row.status === 1 ? scope.row.updateTime || "-" : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="处理人" prop="ompStatus">
                <template slot-scope="scope">
                  {{ scope.row.receiverText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="持续时间" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.interval || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.status === 0"
                    @click="setSaleHandle(scope.row)"
                    type="text"
                    >处理</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mine-table-page">
            <div class="mine-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="历史工单" name="fifth">
        <div class="mine-table-container">
          <div class="mine-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="设备编号" prop="sn" width="200">
                <template slot-scope="scope">
                  <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn || '-'}}</a>
                </template>
              </el-table-column>
              <el-table-column label="派发人" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.senderText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="派发时间" prop="sendTime">
                <template slot-scope="scope">
                  {{ scope.row.sendTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="工单状态" prop="status">
                <template slot-scope="scope">
                  {{ scope.row.orderStatusText || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="工单类型" prop="status">
                <template slot-scope="scope">
                  {{ scope.row.orderTypeText || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="处理时间" prop="ompStatus">
                <template slot-scope="scope">
                  {{
                    scope.row.dealTime || '-'
                  }}
                </template>
              </el-table-column>
              <el-table-column label="持续时间" prop="ompStatus">
                <template slot-scope="scope">
                  {{ scope.row.interval || "-" }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.status === 0"
                    @click="setSaleHandle(scope.row)"
                    type="text"
                    >处理</el-button
                  >
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <div class="mine-table-page">
            <div class="mine-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <CloudSetup v-if="visable" ref="setModel" @refreshDataList="getData"/>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import "./Index.less";
import moment from "moment";
import CloudSetup from './components/CloudSetup'
export default {
  name: "MineJob",
  components: {CloudSetup},
  data() {
    return {
      list: [],
      total: 0,
      visable: false,
      loading: false,
      formData: {
        sn: null,
        status: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        organId: null,
        userId: null,
        startTime: null,
        endTime: null,
        type: null,
        faultStatus: null,
        emergency: null
      },
      activeName: "third",
    };
  },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.formData.userId = localStorage.getItem("userId");
    this.getData2();
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.formData = {
        sn: null,
        status: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        organId: this.formData.organId,
        userId: this.formData.userId,
        startTime: null,
        endTime: null,
        type: null,
        faultStatus: null,
        emergency: null
      }
      if (tab.name === "first") {
        this.getData();
      } else if(tab.name === "second") {
        this.getData1();
      } else if(tab.name === "third") {
        this.getData2()
      } else if(tab.name === 'fourth') {
        this.getOmpData()
      } else {
        this.getHistoryList()
      }
    },
    //获取历史工单
    getHistoryList() {
      this.loading = true;
      axios
        .post(`/api/FaultDealLog/myOrders`, {id: this.formData.userId, pageSize: this.formData.pageSize, pageIndex: this.formData.pageIndex})
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //获取列表数据
    getData() {
      this.loading = true;
      axios
        .post(`/api/faultJob/query`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    getData1() {
      this.loading = true;
      axios
        .post(`/api/postSale/query`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    getData2() {
      this.loading = true;
      axios
        .post(`/api/serviceOrder/pageQuery`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    getOmpData() {
      this.loading = true;
      axios
        .post(`/api/cloudControl/devOpsOrder`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        status: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        startTime: null,
        endTime: null,
        organId: localStorage.getItem("organId"),
        userId: localStorage.getItem("userId"),
        type: null,
        faultStatus: null,
        emergency: null
      };
      if (this.activeName === "first") {
        this.getData();
      } else if (this.activeName === "second"){
        this.getData1();
      } else if(this.activeName === 'third' ) {
        this.getData2();
      } else if(this.activeName === 'fourth') {
        this.getOmpData()
      } else {
        this.getHistoryList()
      }
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.formData.startTime = this.DateToStr(data[0]);
        this.formData.endTime = this.DateToStr(data[1]);
      } else {
        this.formData.startTime = null;
        this.formData.endTime = null;
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },

    handleSizeChange(val) {
      this.formData.pageSize = val;
      if (this.activeName === "first") {
        this.getData();
      } else if (this.activeName === "second"){
        this.getData1();
      } else if(this.activeName === 'third') {
        this.getData2();
      } else if(this.activeName === 'fourth') {
        this.getOmpData()
      } else {
        this.getHistoryList()
      }
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      if (this.activeName === "first") {
        this.getData();
      } else if (this.activeName === "second"){
        this.getData1();
      } else if(this.activeName === "third") {
        this.getData2();
      } else if(this.activeName === 'fourth') {
        this.getOmpData()
      } else {
        this.getHistoryList()
      }
    },
    //跳转详情
    setHandle(row) {
      this.$router.push({
        name: "BackJob",
        params: {
          sn: row.sn,
        },
      });
    },
    setSaleHandle(row) {
      this.$router.push({
        name: "SaleJob",
        params: {
          sn: row.sn,
        },
      });
    },
    gotoCunstomer(item) {
      // this.$router.push({
      //   name: "InfoCustomer",
      //   params: {
      //     id: item.sourceId,
      //     type: 2
      //   },
      // });
      let {href}= this.$router.resolve({
        path: "info-customer",   // 这里写的是要跳转的路由地址
        query: {
          id: item.sourceId,
          type: 2
        }  // 这里写的是页面参数
      });
      window.open(href, '_blank');
    },
    gotoOmp(row) {
      if(row.type === 1) {
        this.$router.push({
          name: "CloudControl",
          params: {
            sn: row.sn,
            type: row.type,
            activeName: 'first'
          },
        });
      } else if(row.type === 2) {
        this.$router.push({
          name: "CloudControl",
          params: {
            sn: row.sn,
            type: row.type,
            activeName: 'third'
          },
        });
      } else if(row.type === 3) {
        this.$router.push({
          name: "CloudControl",
          params: {
            sn: row.sn,
            type: row.type,
            activeName: 'second'
          },
        });
      }  else if(row.type === 4) {
        this.$router.push({
          name: "CustomerInformation",
          params: {
            sn: row.sn,
          },
        });
      } 
    },
    gotoDetail(row) {
      this.$router.push({
        name: "DeviceDetail",
        params: {
          sn: row.sn,
          imei: row.sn,
          type: 1
        },
      });
    }
  },
  filters: {
    statusStr(value) {
      if (value !== null || value !== undefined) {
        if (value === 1) {
          return "已处理";
        } else if (value === 0) {
          return "未处理";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    typeStr(value) {
      if (value !== null || value !== undefined) {
        if (value === 1) {
          return "故障";
        } else if (value === 2) {
          return "低电量";
        } else if (value === 3){
          return "离线";
        } else if (value === 4){
          return "客服";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    }
  },
};
</script>